<template>
    <BT-Blade-Items
        addBladeName="location"
        bladeName="locations"
        :bladesData="bladesData"
        canAdd
        canDelete
        clearBlades
        :headers="[
            { text: 'Name', value: 'locationName', title: 1 },
            { text: 'Address', selfIsValue: true, textFilter: 'toLocationLine', subtitle: 2 }]"
        navigation="locations"
        :onFilter="list => { return list.orderBy('locationName') }"
        :onSelect="onSelect"
        title="Locations"
        useServerPagination
        :defaultBladeWidth="300" />
</template>

<script>
export default {
    name: 'Locations-Blade',
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        onSelect: {
            type: Function,
            default: null
        },
        width: null,

    }
}
</script>